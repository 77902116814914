const palette = {
  color_W: '#ffffff',
  color_P: '#7c2d87',
  color_PL: '#e6cfeb',
  color_G: '#d4d4d4',
  color_GL: '#cbcbcb',
  color_GLL: '#EBEBEB',
  color_B: '#353535',
  color_M: '#00bfb5',
  color_ML: '#8ee7e2',
  color_R: '#dc3c3c',
};

export type Palette = typeof palette;
const theme = { palette };

export default theme;
