import { createSlice } from '@reduxjs/toolkit';

interface locationType {
  bbox: number[];
  center: number[];
  place_name: string;
}

interface reducerType {
  mapSelect: number;
  mapDrawMode: 'select' | 'rectangle' | 'polygon' | 'line' | 'round' | 'delete';
  location: locationType | null;
  mapStyle: 'satellite' | 'light' | 'street';
  point: [number, number] | null;
}

const initialState: reducerType = {
  mapSelect: -1,
  mapDrawMode: 'select',
  location: null,
  mapStyle: 'satellite',
  point: null,
};

export const slice = createSlice({
  name: 'control',
  initialState,
  reducers: {
    changeMapSelectNumber: (state, action) => {
      state.mapSelect = action.payload;
    },
    changeMapDrawMode: (state, action) => {
      state.mapDrawMode = action.payload;
    },
    updateLocationInfo: (state, action) => {
      state.location = action.payload;
    },
    changeMapStyle: (state, action) => {
      state.mapStyle = action.payload;
    },
    changePointInfo: (state, action) => {
      state.point = action.payload;
    },
  },
});

export const reducer = slice.name;
export const controlReducer = slice.reducer;
export const controlAction = slice.actions;
