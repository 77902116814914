import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing:border-box;
        font-family: 'SUIT';
    }
    a {
        text-decoration:none;
        color:inherit;
    }
    html,body{
        margin:0;
        padding:0;
        color:#353535;
    }
    ul,li{
        list-style:none;
        margin:0;
        padding:0;
    }
  
`;

export default GlobalStyle;
