import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from '@styles/index';
import { appWithTranslation } from 'next-i18next';
import { Amplify } from 'aws-amplify';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import '@styles/font.css';
import wrapper from 'store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

function MyApp({ Component, pageProps }) {
  const { store, props } = wrapper.useWrappedStore(pageProps);
  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    });
  }

  const router = useRouter();
  useEffect(() => {
    const handleStart = (url: string, { shallow }) => {
      NProgress.start();
    };
    const handleStop = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 1500,
    transition: transitions.SCALE,
  };

  return (
    <>
      <Head>
        <title>NARA SPACE - Earthpaper</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
      </Head>
      <GlobalStyle />
      <Provider store={store}>
        <QueryClientProvider client={queryClientRef.current}>
          <Hydrate state={pageProps.dehydratedStaHydratete}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ThemeProvider theme={theme}>
              <AlertProvider template={AlertTemplate} {...options}>
                <Component {...props} />
              </AlertProvider>
            </ThemeProvider>
          </Hydrate>
        </QueryClientProvider>
      </Provider>
    </>
  );
}

export default appWithTranslation(MyApp);
